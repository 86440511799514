@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (min-width: 640px) {
  html {
    background: #ffffff;
  }
}

@layer base {
  html {
    @apply font-sans text-text;
    overflow-y: scroll;
    height: 100%;
    background: #f4f4f4;
  }

  body,
  body > div:first-child,
  div#authed-page-wrapper {
    height: 100%;
  }

  h1 {
    @apply text-h1 font-bold;
  }

  h2 {
    @apply text-h2 font-extrabold;
  }

  h3 {
    @apply text-h3 font-extrabold;
  }

  h4 {
    @apply text-h4 font-extrabold;
  }

  h5 {
    @apply text-h5 font-extrabold;
  }

  h6 {
    @apply text-h6 font-bold;
  }

  p {
    @apply pb-2;
  }

  .bg-gradient-full-range {
    background: linear-gradient(247.24deg, #1256fe 7.34%, #00cc8b 77.78%);
  }

  .bg-gradient-full-range-dark {
    background: linear-gradient(73.25deg, #017f76 23.59%, #00385b 87.14%);
  }

  .bg-gradient-mid-range-blue {
    background: linear-gradient(244.66deg, #2795ee 5.59%, #3740f3 77.69%);
  }

  .bg-gradient-mid-range-green {
    background: linear-gradient(99.79deg, #02c494 21.72%, #06a59e 55.33%);
  }

  .absolute-center {
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
  }

  body .sliding-animation {
    transition: all 0.25s ease, height 0s 0s;
  }

  body .sliding-animation-in {
    transition: all 0.25s ease, height 0s 0.2s;
  }

  .article p {
    margin: 0.5rem 0;
  }

  .mobile-padding-bottom {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .mobile-padding-top {
    padding-top: env(safe-area-inset-top);
  }

  .mobile-safe-vh {
    min-height: 100vh;
    height: 100vh;
    min-height: -webkit-fill-available;
    height: 100vh;
  }

  *:focus,
  *:hover {
    -webkit-tap-highlight-color: transparent;
  }

  /* Remove the up and down toggles on number fields (looks terrible on things like adding your BSB  */
  /* For Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* For Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  /* Primarily used in Caurosel */
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Shadows */
  .card-elevation-6 {
    box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
      0px 3px 5px -1px rgba(0, 0, 0, 0.2);
  }
}
